import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Input, Spacer } from "@nextui-org/react";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  Link,
} from "@nextui-org/react";
import { Icon } from "@iconify/react";

import clsx from "clsx";
import { twMerge } from "tailwind-merge";

function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export default function Form(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [count, setCount] = useState("");
  const [name, setName] = useState("");
  const { register, handleSubmit } = useForm();

  const onSubmit = (d) => {
    if (props.data.period === "free") {
    } else if (!name || !count) {
      props.notify("Fill the fields");
      return;
    } else if (count < props.data.min) {
      props.notify("Min Count: " + props.data.min);
      return;
    }
    d = {
      ...d,
      desc: props.data.desc,
      price: (props.data.price * count).toFixed(2),
    };
    props.func(d);
    setCount(null);
    setName(null);
    setIsOpen(false);
  };

  const content = isOpen ? (
    <div className="h-full w-full items-start justify-center px-4 pb-24 pt-20">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-2">
          <Input
            autoFocus
            fullWidth
            aria-label={props.data.title}
            classNames={{
              inputWrapper:
                "group-data-[focus-visible=true]:outline-foreground",
            }}
            size="lg"
            autoComplete="off"
            type="text"
            value={name}
            label={props.data.title}
            labelPlacement="outside"
            variant="bordered"
            placeholder={props.data.placeholder}
            {...register("name", {
              onChange: (e) => setName(e.target.value),
            })}
          />
          <Input
            fullWidth
            aria-label={props.data.placeholder2}
            classNames={{
              inputWrapper:
                "group-data-[focus-visible=true]:outline-foreground",
            }}
            size="lg"
            autoComplete="off"
            type="number"
            label={props.data.placeholder2}
            labelPlacement="outside"
            value={count}
            placeholder={props.data.min}
            variant="bordered"
            {...register("count", {
              onChange: (e) => setCount(e.target.value),
            })}
          />
          <Divider className="mb-8 mt-10" />
          <Button className="text-black font-semibold mt-1" type="submit" color="warning">
            Buy for ${(props.data.price * count).toFixed(2)}
          </Button>
        </div>
      </form>
    </div>
  ) : (
    <ul>
      {props.data.about &&
        props.data.about.map((item) => {
          return (
            <li className="flex items-center gap-1">
              <Icon className="text-default-600" icon="ci:check" width={24} />
              <p className="text-small text-default-500">{item}</p>
            </li>
          );
        })}
    </ul>
  );

  return (
    <Card {...props} className="relative w-full max-w-[400px] pb-[120px] border-solid border-1 border-gray-500 ccard">
      <Button
        className="absolute right-4 top-8 z-10 text-black font-bold"
        isIconOnly={isOpen}
        color="warning"
        size="md"
        radius="md"
        onPress={() => setIsOpen((prev) => !prev)}
      >
        {isOpen ? <Icon icon="ci:close-sm" width={24} /> : "Buy Now"}
      </Button>
      <CardBody className="relative min-h-[300px] bg-gradient-to-br from-content1 to-default-100/50 p-8 before:inset-0 before:h-full before:w-full before:content-['']">
        <h1 className="mb-4 text-default-600 font-bold absolute">
          {props.data.platform.toUpperCase()}
        </h1>
        <h2 className="inline bg-gradient-to-br from-foreground-800 to-foreground-500 bg-clip-text text-6xl font-semibold tracking-tight dark:to-foreground-200 absolute top-20">
          {props.data.titlemain.split(" ").map((word, index) => (
            <React.Fragment key={index}>
              {word} <br />
            </React.Fragment>
          ))}
        </h2>
      </CardBody>
      <CardFooter
        className={cn(
          "absolute bottom-0 h-[120px] overflow-visible bg-content1 px-6 duration-300 ease-in-out transition-height",
          {
            "h-full": isOpen,
            "border-t-1 border-default-100": !isOpen,
          }
        )}
      >
        {content}
      </CardFooter>
    </Card>
  );
}
